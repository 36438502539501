@import '~antd/dist/antd.css';

input {
    outline         : none;
    border          : none;
    background-color: transparent;
}

// .ant-message-notice-content {
//     width        : 183px;
//     height       : 56px;
//     background   : rgba(0, 0, 0, 0.8);
//     border-radius: 6px;

//     span {
//         font-size  : 18px;
//         font-family: PingFangSC-Light, PingFang SC, Serif;
//         font-weight: 300;
//         color      : #FFFFFF;
//         line-height: 25px;
//     }
// }
